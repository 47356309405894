import { createStore } from 'vuex'

export default createStore({
  state: {
    query: '',
  },
  getters: {
    getQuery: (state) => state.query,
  },
  mutations: {
    setQuery(state, newQuery) {
      state.query = newQuery;
    },
  },
  actions: {
    updateQuery({ commit }, newQuery) {
      commit('setQuery', newQuery);
    },
  },
  modules: {
  }
})
